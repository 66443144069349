exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lattigo-tsx": () => import("./../../../src/pages/lattigo.tsx" /* webpackChunkName: "component---src-pages-lattigo-tsx" */),
  "component---src-pages-platform-tsx": () => import("./../../../src/pages/platform.tsx" /* webpackChunkName: "component---src-pages-platform-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-product-tour-tsx": () => import("./../../../src/pages/product-tour.tsx" /* webpackChunkName: "component---src-pages-product-tour-tsx" */),
  "component---src-pages-product-tours-tsx": () => import("./../../../src/pages/product-tours.tsx" /* webpackChunkName: "component---src-pages-product-tours-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */)
}

